import React from 'react';
import './signup.css';
import Rectangle from '../../../assets/image/Rectangle.png';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import { Button, Checkbox, Form, Input } from 'antd';
import TextInput from '../../../components/TextInput/TextInput';
import { Link, useNavigate } from 'react-router-dom';
import EmailIcon from '../../../assets/icon/email.svg';
import lockIcon from '../../../assets/icon/padlock.svg';
import UserIcon from '../../../assets/icon/utilisateur.svg';
import { useDispatch, useSelector } from 'react-redux';
import { requestSignUp, verifAccount } from '../../../redux/actions/auth/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userIdSelector = useSelector((state) => state.auth);
  const notify = () => toast.info('Veuillez confirmer votre compte par Email');
  const onFinish = (values) => {
    try {
      dispatch(requestSignUp({ userName: values.Username, email: values.Email, password: values.password, confirmPassword: values.confirmPassword }));
      // dispatch(verifAccount('3'));
      // notify();
    } catch (error) {
      console.log('err==>', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="form-builder-wrapper">
      <div className="form_block">
        <div className="form-builder_logo">
          <img src={kwanto_logo} alt="kwanto_logo" />
        </div>

        <div className="text_block">
          <div className="create-account">Créer votre compte</div>
          <div className="quest">
            <div>Vous avez déjà un compte ?</div>
            <div>vous pouvez accéder à compte par ici</div>
            <Link id="form-builder_text-link" to="/login">
              Se connecter !
            </Link>
            {/* <div id="form-builder_text-link">Se connecter !</div> */}
          </div>
        </div>
        <div className="form-builer_inputs_signup">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on">
            <TextInput
              label="Nom et prénom"
              name="Username"
              placeholder="Entrer votre nom et prénom"
              icon={UserIcon}
              icon_ajustement="0px"
              required={true}
              message="nom et prénom obligatoires"
            />
            <TextInput
              label="Email"
              type="email"
              name="Email"
              placeholder="Entrer votre adresse mail"
              icon={EmailIcon}
              icon_ajustement="7px"
              required={true}
              message={'Email Obligatoire'}
            />
            <TextInput
              label="Mot de passe"
              name="password"
              placeholder="Entrer votre mot de passe"
              icon={lockIcon}
              required={true}
              message={'mot de passe Obligatoire'}
            />
            <TextInput
              label="Confirmation mot de passe "
              name="confirmPassword"
              placeholder="Confirmer votre mot de passe"
              icon={lockIcon}
              required={true}
              message={'confirmer mot de passe Obligatoire'}
            />
            {/* <div className="bottom_form">
              <Form.Item name="remember" className="form-builder_remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox style={{ width: 'max-content' }}>Rester connecté</Checkbox>
              </Form.Item>
            </div> */}
            <Form.Item style={{ paddingTop: '1vh' }}>
              <Button className="form-builder_btn" type="primary" htmlType="submit">
                Créer mon compte
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="image_block">
        <img className="Rectangle_img" src={Rectangle} alt="Rectangle img" />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
