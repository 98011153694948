// export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
// export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const VERIF_ACCOUNT_SUCCESS = 'VERIF_ACCOUNT_SUCCESS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const VERIF_ACCOUNT_FAIL = 'VERIF_ACCOUNT_FAIL';
