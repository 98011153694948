import http from './http-common';

const create = (data) => {
  return http.post('/auth', data).then((response) => {
    return response.data;
  });
};
const login = (data) => {
  return http.post('/auth/login', data).then((response) => {
    return response.data;
  });
};
const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
};

const verifyAccount = (userId, token) => {
  return http.post(`/auth/confirmEmail?userId=${userId}`).then((response) => {
    return response.data;
  });
};

const resetPassword = (email) => {
  return http.post(`/auth/resetPassword`, { email: email }).then((response) => {
    return response.data;
  });
};

const changePassword = (data) => {
  return http.post(`/auth/changePassword?token=${data.token}&userId=${data.userId}`, data).then((response) => {
    return response.data;
  });
};

export default {
  create,
  login,
  logout,
  verifyAccount,
  resetPassword,
  changePassword
};
