const filterSoldeByColor = (status) => {
  switch (status) {
    case 1:
      return 'rgba(51, 204, 0, 0.3)';
    case 2:
      return 'rgba(96, 223, 54, 0.7)';
    case 3:
      return 'rgba(51, 204, 0, 0.9)';
    case 'Relle':
      return 'rgba(51, 153, 204, 0.5) ';
    case 'Simulation':
      return 'rgba(255, 255, 255, 0.3)';
    default:
      return 'rgba(255, 255, 255, 0.3)';
  }
};

export { filterSoldeByColor };
