// Import icon in sidebar
import categorie from '../assets/icon/List.svg';
import home from '../assets/icon/home.svg';
import chart from '../assets/icon/charts.svg';
import notif from '../assets/icon/notif.svg';
import settings from '../assets/icon/settings.svg';
import logout from '../assets/icon/logout.svg';

export const SidebarData = [
  {
    name: 'Home',
    exact: true,
    path: '/',
    icon: home,
    isActive: true,
    activeClass: false
  },
  {
    name: 'Elements',
    path: '/categories',
    icon: categorie,
    isActive: false,
    activeClass: false
  },
  {
    name: 'Chart',
    exact: true,
    path: '/chart',
    icon: chart,
    isActive: false,
    activeClass: false
  },
  {
    name: 'Notification',
    exact: true,
    path: '/notif',
    icon: notif,
    isActive: false,
    activeClass: false
  },
  {
    name: 'Settings',
    exact: true,
    path: '/settings',
    icon: settings,
    isActive: false,
    activeClass: false
  },
  {
    name: 'Logout',
    exact: true,
    path: '/login',
    icon: logout,
    isActive: false,
    activeClass: true
  }
];
