import http from './http-common';

const getState = (userId, startDate, endDate, selectedStatut) => {
  const params = {};
  params.startDate = startDate;
  params.endDate = endDate;
  if (selectedStatut) {
    params.selectedStatut = selectedStatut;
  }
  return http.get(`/state/${userId}`, { params: params }).then((response) => {
    return response.data;
  });
};

export default {
  getState
};
