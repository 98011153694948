import React from 'react';
import { getQueryStringParams } from '../../helpers/helpers';

export default function TdElement({ monthTotal, children, expanded, dataIndex, refresh, setRefresh, dataSource, setDataSource, ...props }) {
  return (
    <>
      <td
        className={dataIndex && dataIndex === 'name' ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory' : 'table-td '}>
        <div
          style={
            dataIndex && dataIndex === 'name'
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '10px'
                }
              : {}
          }>
          {children?.map((c) => {
            return c ? <div key={getQueryStringParams(c?.props.dragPrefix).category || new Date().toString()}>{React.cloneElement(c)}</div> : null;
          })}
        </div>
      </td>
    </>
  );
}
