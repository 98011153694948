import { LIST_STATE_FAIL, LIST_STATE_SUCCESS } from './type';
import StateService from '../../../services/StateService';

export const getState = (userId, startDate, endDate, value) => async (dispatch) => {
  try {
    const res = await StateService.getState(userId, startDate, endDate, value);

    dispatch({
      type: LIST_STATE_SUCCESS,
      payload: res.data.dataState
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: LIST_STATE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};
