import {
  CREATE_Element_SUCCESS,
  CREATE_Element_FAIL,
  LIST_Element_SUCCESS,
  LIST_Element_FAIL,
  GET_ElementS_SUCCESS,
  GET_ElementS_FAIL,
  GET_Element_ID
} from '../../actions/element/type';

const initialState = {
  elements: [],
  element: null,
  idElement: null
};
const elementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ElementS_SUCCESS:
      return {
        ...state,
        elements: payload
      };
    case GET_ElementS_FAIL:
      return {
        ...state,
        elements: payload
      };
    case GET_Element_ID:
      return {
        ...state,
        idElement: payload
      };
  }

  return <div>index</div>;
};

export default elementReducer;
