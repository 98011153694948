import { CREATE_OBJECTIVE_FAIL } from '../../actions/objective/type';

const initialState = {
  objective: []
};
const objectiveReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_OBJECTIVE_FAIL:
      return {
        ...state,
        createFail: payload
      };
  }

  return <div>index</div>;
};

export default objectiveReducer;
