import React, { useEffect } from 'react';
import './forgetPassword.css';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import mail_sent from '../../../assets/image/mail_sent.png';
import EmailIcon from '../../../assets/icon/email.svg';

import { Button, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { isValidEmail } from '../../../helpers/isEmail';
import TextInput from '../../../components/TextInput/TextInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ForgetPassword = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      if (values.Email && isValidEmail(values.Email)) {
        await AuthService.resetPassword(values.Email);
        navigate(`/sendEmail`);
      }
    } catch (error) {
      if (error.response.status == 404) {
        toast.error('Utilisateur introuvable');
      }
    }
  };

  return (
    <div className="confirm-container">
      <div className="confirm-logos">
        <img src={kwanto_logo} alt="kwanto_logo" />
        <img className="mail-sent" src={mail_sent} alt="mail_sent" />
        <div className="p-forget">
          <p className="confirmation-p"> Retrouvez votre compte</p>
          <p className="second-p">
            Saisissez votre l&apos;adresse e-mail avec laquelle vous vous êtes inscrit. <br />
            Nous vous enverrons par e-mail un lien pour vous connecter et réinitialiser votre mot de passe.
          </p>
        </div>
        <Form className="button-env" name="basic" labelCol={{ span: 8 }} autoComplete="on" onFinish={onFinish}>
          <TextInput
            label="Email"
            name="Email"
            placeholder="Entrer votre adresse mail"
            icon={EmailIcon}
            icon_ajustement="7px"
            required={true}
            message="Email obligatoire"
            type="email"
          />
          <Form.Item>
            <Button className="btn-navig" type="primary" htmlType="submit">
              Envoyer
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgetPassword;
