import React from 'react';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import mail_sent from '../../../assets/image/mail_sent.png';
import './sendEmailForgetPassword.css';
import { Button, Form } from 'antd';
import { useNavigate } from 'react-router-dom';

const SendEmailForgetPassword = () => {
  const navigate = useNavigate();

  const clickLogin = () => {
    navigate('/login');
  };
  const clickCreate = () => {
    navigate('/signup');
  };
  return (
    <div className="confirm-container">
      <div className="confirm-logos">
        <img src={kwanto_logo} alt="kwanto_logo" />
        <img className="mail-sent" src={mail_sent} alt="mail_sent" />
        <div className="p-forget">
          <p className="confirmation-p">Mot de passe oublié?</p>
          <p className="second-p">
            Veuillez consulter votre boite mail
            <br />
            Nous avons envoyé un email de réinitialisation de mot de passe
          </p>
        </div>
        <Form className="two-button-conf-from" name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
          <Form.Item className="buttons">
            <Button className="btn-navig" onClick={clickLogin} type="primary" htmlType="submit">
              Se connecter
            </Button>
            <Button className="btn-navig" onClick={clickCreate} type="primary" htmlType="submit">
              Créer mon compte
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SendEmailForgetPassword;
