import React, { useState, useEffect } from 'react';
import './signin.css';
import Rectangle from '../../../assets/image/Rectangle.png';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import { Button, Checkbox, Form, Input } from 'antd';
import TextInput from '../../../components/TextInput/TextInput';
import { Link, useNavigate } from 'react-router-dom';
import EmailIcon from '../../../assets/icon/email.svg';
import lockIcon from '../../../assets/icon/padlock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { requestLogin } from '../../../redux/actions/auth/index';
import { isValidEmail } from '../../../helpers/isEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Signin = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const signinSelector = useSelector((state) => state.auth.access_token);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    try {
      dispatch(requestLogin({ email: values.Email, password: values.password }));
    } catch (error) {
      console.log('err==>', error);
    }
  };
  const onChangeEmail = (values) => {
    setEmail(values.target.form[0].value);
  };

  const goToForget = () => {
    navigate(`/forgetPassword`);
  };

  useEffect(() => {
    if (signinSelector) {
      navigate('/');
    }
  }, [signinSelector]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="form-builder-wrapper">
      <div className="form_block">
        <div className="form-builder_logo">
          <img src={kwanto_logo} alt="kwanto_logo" />
        </div>

        <div className="text_block">
          <div className="connect-text">Se connecter</div>
          <div className="quest">
            <div>Vous êtes nouveau ici ?</div>
            <div>vous pouvez créer votre compte par ici</div>
            <Link id="form-builder_text-link" to="/signup">
              Nouveau compte !
            </Link>
          </div>
        </div>
        <div className="form-builer_inputs">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onChange={onChangeEmail}
            autoComplete="on">
            <TextInput
              label="Email"
              name="Email"
              placeholder="Entrer votre adresse mail"
              icon={EmailIcon}
              icon_ajustement="7px"
              required={true}
              message="Email obligatoire"
            />
            <TextInput
              label="Mot de passe"
              name="password"
              icon={lockIcon}
              placeholder="Entrer votre mot de passe"
              required={true}
              message="Mot de passe obligatoire"
            />
            <div className="bottom_form">
              <Form.Item name="remember" className="form-builder_remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                {/* <Checkbox style={{ width: 'max-content' }}>Rester connecté</Checkbox> */}
              </Form.Item>
              <div onClick={goToForget} className="form-builder_forget">
                Mot de passe oublié?
              </div>
            </div>
            <Form.Item style={{ paddingTop: '7vh' }}>
              <Button className="form-builder_btn" type="primary" htmlType="submit">
                Se connecter
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="image_block">
        <img className="Rectangle_img" src={Rectangle} alt="Rectangle img" />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signin;
