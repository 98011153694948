import http from './http-common';

const create = (data, title, startDate, endDate, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.post('/objectives', data, { params: params }).then((response) => {
    return response.data;
  });
};

const update = (id, data, title, startDate, endDate, startDateSynthese, endDateSynthese) => {
  const params = {};
  if (title) {
    params.title = title;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  params.startDateSynthese = startDateSynthese;
  params.endDateSynthese = endDateSynthese;
  return http.put(`/objectives/${id}`, data, { params: params }).then((response) => {
    return response.data;
  });
};
const deleteObjectif = (id, userId) => {
  return http.delete(`/objectives/${id}-${userId}`).then((response) => {
    return response.data;
  });
};
const getAll = (userId) => {
  return http.get(`/objectives/all/${userId}`).then((response) => {
    return response.data;
  });
};

export default {
  create,
  getAll,
  update,
  deleteObjectif
};
