import React, { useEffect } from 'react';
import './changePassword.css';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import mail_sent from '../../../assets/image/mail_sent.png';
import lockIcon from '../../../assets/icon/padlock.svg';
import TextInput from '../../../components/TextInput/TextInput';

import { Button, Form } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthService from '../../../services/AuthService';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const onFinish = async (values) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      const userId = searchParams.get('userId');
      await AuthService.changePassword({ newPassword: values.password, confirmNewPassword: values.confirmPassword, userId: userId, token: token });
      navigate('/login');
    } catch (error) {
      console.log('err==>', error);
    }
  };

  return (
    <div className="confirm-container">
      <div className="confirm-logos">
        <img src={kwanto_logo} alt="kwanto_logo" />
        <div className="p-forget">
          <p className="confirmation-p">Nouveau mot de passe</p>
          <p className="second-p">
            Saisissez votre nouveau mot de passe
            <br />
            Puis appuyez sur Modifier le mot de passe
          </p>
        </div>
        <Form className="changePasswd-form" name="basic" onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
          <TextInput label="Mot de passe" name="password" placeholder="Entrer votre mot de passe" icon={lockIcon} />
          <TextInput label="Confirmation mot de passe " name="confirmPassword" placeholder="Confirmer votre mot de passe" icon={lockIcon} />
          <Form.Item className="buttons">
            <Button className="btn-change" type="primary" htmlType="submit">
              Modifier le mot de passe
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
