import React, { useEffect } from 'react';
import './confirmEmail.css';
import kwanto_logo from '../../../assets/icon/kwanto_logo_SVG.svg';
import mail_sent from '../../../assets/image/mail_sent.png';

import { Button, Form } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifAccount } from '../../../redux/actions/auth/index';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  let userId = params.confirmation_token;
  //   let token = params.confirmation_token.split('-')[1];
  const onFinish = (values) => {
    try {
      dispatch(verifAccount(userId));
      navigate('/login');
    } catch (error) {
      console.log('err==>', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="confirm-container">
      <div className="confirm-logos">
        <img src={kwanto_logo} alt="kwanto_logo" />
        <img className="mail-sent" src={mail_sent} alt="mail_sent" />
        <div>
          <p className="confirmation-p">Confimation de compte</p>
          <p className="second-p">
            Veuillez confirmer votre compte
            <br />
            en cliquant sur ce boutton
          </p>
        </div>
        <Form
          className="button-conf-from"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item>
            <Button className="btn-confirm" type="primary" htmlType="submit">
              Confirmer votre compte
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ConfirmEmail;
