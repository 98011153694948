const chekDate = (date) => {
  switch (date.split(' ')[0]) {
    case 'January':
      return `${'01-01-' + date.split(' ')[1]}`;
    case 'February':
      return `${'01-02-' + date.split(' ')[1]}`;
    case 'March':
      return `${'01-03-' + date.split(' ')[1]}`;
    case 'April':
      return `${'01-04-' + date.split(' ')[1]}`;
    case 'May':
      return `${'01-05-' + date.split(' ')[1]}`;
    case 'June':
      return `${'01-06-' + date.split(' ')[1]}`;
    case 'July':
      return `${'01-07-' + date.split(' ')[1]}`;
    case 'August':
      return `${'01-08-' + date.split(' ')[1]}`;
    case 'September':
      return `${'01-09-' + date.split(' ')[1]}`;
    case 'October':
      return `${'01-10-' + date.split(' ')[1]}`;
    case 'November':
      return `${'01-11-' + date.split(' ')[1]}`;
    case 'December':
      return `${'01-12-' + date.split(' ')[1]}`;
    default:
      break;
  }
};

export { chekDate };
