import ObjectiveService from '../../../services/ObjectiveService';
import { LIST_CATEGORIE_SUCCESS_FINAL } from '../categories/type';

import {
  CREATE_OBJECTIVE_SUCCESS,
  CREATE_OBJECTIVE_FAIL,
  LIST_OBJECTIVE_SUCCESS,
  LIST_OBJECTIVE_FAIL,
  UPDATE_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_FAIL,
  LIST_ALL_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_SUCCESS
} from './type';

export const addNewObjectif = (data, title, startDate, endDate, startDateSynthese, endDateSynthese) => async (dispatch) => {
  try {
    const res = await ObjectiveService.create(data, title, startDate, endDate, startDateSynthese, endDateSynthese);

    dispatch({
      type: CREATE_OBJECTIVE_SUCCESS,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_OBJECTIVE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};

export const deleteObjectif = (id, userId) => async (dispatch) => {
  try {
    const res = await ObjectiveService.deleteObjectif(id, userId);

    dispatch({
      type: DELETE_OBJECTIVE_SUCCESS,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_OBJECTIVE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};

export const updateObjectif = (id, data, title, startDate, endDate, startDateSynthese, endDateSynthese) => async (dispatch) => {
  try {
    const res = await ObjectiveService.update(id, data, title, startDate, endDate, startDateSynthese, endDateSynthese);

    dispatch({
      type: UPDATE_OBJECTIVE_SUCCESS,
      payload: res.data.copie
    });
    dispatch({
      type: LIST_CATEGORIE_SUCCESS_FINAL,
      payload: res.data.totalSoldesMonth
    });

    dispatch({
      type: LIST_OBJECTIVE_SUCCESS,
      payload: res.data.objectiveAll
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: UPDATE_OBJECTIVE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};

export const getAllObjective = (userId) => async (dispatch) => {
  try {
    const res = await ObjectiveService.getAll(userId);

    dispatch({
      type: LIST_OBJECTIVE_SUCCESS,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: LIST_OBJECTIVE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};
