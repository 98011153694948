// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import categories from './categories';
import elements from './element';
import auth from './auth';
import objective from './objective';

const rootReducer = combineReducers({
  categories,
  elements,
  auth,
  objective
});

export default rootReducer;
